// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigation } from '../utils/navigation';
import companyLogo from '../assets/logo.webp';
import '../styles/Footer.css'; // Import the existing CSS file

const Footer = () => {
  const handleNavigation = useNavigation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section footer-logo">
          <img src={companyLogo} alt="Company Logo" />
        </div>
        <div className="footer-section footer-contact">
          <div className="footer-header">יצירת קשר:</div>
          <p>
            מספר טלפון: <a href="tel:+0586540013">0586540013</a>
          </p>
          <p>
            צ'אט בווטסאפ: <a href="https://wa.me/+972586540013" target="_blank" rel="noopener noreferrer">0586540013</a>
          </p>
          <p>
            פנייה במייל: <a href="mailto:contact@shedrmeals.com">contact@shedrmeals.com</a>
          </p>
          <p className="contact-heading">זמני פעילות שירות לקוחות:</p>
          <p className="contact-hours">10:00 עד 17:00</p>
        </div>
        <div className="footer-section footer-links">
          <div className="footer-header">מידע:</div>
          <ul>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleNavigation('/about', 'faq-container'); }}>שאלות ותשובות</a>
            </li>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleNavigation('/about'); }}>אודות</a>
            </li>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleNavigation('/store'); }}>תפריט</a>
            </li>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleNavigation('/'); }}>דף בית</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;