// src/components/Benefits.js
import React from 'react';
import '../styles/Benefits.css'; // Import the CSS file for styling

// Import icons
import deliveryIcon from '../assets/delivery.webp';
import foodContainers from '../assets/food_containers.webp';
import freshIngredients from '../assets/fresh_ingredients.webp';
import orderReceived from '../assets/order_received.webp';
import locations from '../assets/locations.webp';

const benefits = [
  {
    icon: foodContainers,
    text: "ארוחות מוכנות ומדודות לשבוע שלם"
  },
  {
    icon: freshIngredients,
    text: "שימוש במרכיבים טריים ומקומיים"
  },
  {
    icon: orderReceived,
    text: "הזמנה קלה ונוחה עד יום חמישי בצהריים"
  },
  {
    icon: deliveryIcon,
    text: "משלוח חינם בהזמנה מעל 250 ₪"
  },
  {
    icon: locations,
    text: "אזורי חלוקה בראשון לציון והסביבה"
  },
];

const splitTextIntoLines = (text, charsPerLine) => {
  const words = text.split(' ');
  let lines = [];
  let currentLine = '';

  words.forEach(word => {
    if ((currentLine + word).length > charsPerLine) {
      lines.push(currentLine.trim());
      currentLine = '';
    }
    currentLine += word + ' ';
  });

  if (currentLine) {
    lines.push(currentLine.trim());
  }

  return lines;
};

const Benefits = () => {
  return (
    <section className="benefits">
      <h2 className="section-header">היתרונות שלנו</h2>
      <div className="benefits-container">
        {benefits.map((benefit, index) => {
          const lines = splitTextIntoLines(benefit.text, 13); // Adjust 15 to your preferred character count per line
          return (
            <div className="benefit-item" key={index}>
              <img src={benefit.icon} alt={`Benefit ${index + 1}`} className="benefit-icon" />
              <div className="benefit-text">
                {lines.map((line, lineIndex) => (
                  <p key={lineIndex} className="benefit-line">{line}</p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Benefits;