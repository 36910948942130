import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import ReactGA from "react-ga4";
import Header from './components/Header';
import Hero from './components/Hero';
import BriefAboutUs from './components/BriefAboutUs';
import HotItems from './components/HotItems'; // Ensure you have this component
import Reviews from './components/Reviews'; // Import the Reviews component
import BriefHowItWorks from './components/BriefHowItWorks';
import Facts from './components/Benefits'; // Import the Facts component
import Footer from './components/Footer'; // Import the Footer component
import AboutPage from './pages/AboutPage'; // Import the AboutPage component
import StorePage from './pages/StorePage'; // Import the StorePage component
import CartPage from './components/CartPage'; // Import the CartPage component
import CheckoutPage from './components/CheckoutPage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import { CartProvider } from './contexts/CartContext'; // Import CartProvider
import WhatsAppIcon from './components/WhatAppIcon';

// Initialize GA with your measurement ID
ReactGA.initialize("G-NEHYC32J8M"); // Replace with your actual tracking ID

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <>
      <Header />
      <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          {/* Add other routes here */}
          <Route path="/" element={
            <>
              <Hero />
              <HotItems />
              <BriefHowItWorks />
              <BriefAboutUs />
              <Reviews /> {/* Add the Reviews section here */}
              <Facts /> {/* Add the Facts section */}
            </>
          } />
      </Routes>
      <WhatsAppIcon />
      <Footer />
    </>
  );
}

function App() {
  return (
    <CartProvider> {/* Wrap everything with CartProvider */}
      <Router> {/* Wrap everything with BrowserRouter */}
        <AppContent />
      </Router>
    </CartProvider>
  );
}

export default App;
