// src/components/BriefAboutUs.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BriefAboutUs.css';
import { useNavigation } from '../utils/navigation';

const BriefAboutUs = () => {
    const handleNavigation = useNavigation();

  return (
    <section className="brief-about-us">
      <h2>מי אנחנו</h2>
      <p>
        אנחנו מאמינים שתזונה נכונה היא המפתח להשגת מטרות בריאותיות ואימוניות. מתוך ניסיון אישי, הבנו את האתגרים בהכנת ארוחות בריאות ומאוזנות. לכן, יצרנו פתרון המספק ארוחות טעימות, מזינות ומדודות, מוכנות מראש לכל השבוע. אנחנו מביאים לכם את הנוחות של אוכל בריא וזמין, ללא הצורך בתכנון מורכב או בזבוז זמן יקר.
      </p>
      <button onClick={() => handleNavigation('/about')} className="learn-more-button">
        למד עוד עלינו
      </button>
    </section>
  );
};

export default BriefAboutUs;