import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import '../styles/Reviews.css';

// Import Swiper modules
import { Navigation, Autoplay } from 'swiper/modules';

// Import images
import img1 from '../assets/rom.webp';
import img2 from '../assets/tatiana.webp';
import img3 from '../assets/yair.webp';
import img4 from '../assets/ilan.webp';
import img5 from '../assets/lior.webp';
import img6 from '../assets/yair.webp';

// Import arrow images
import arrowLeft from '../assets/left-arrow.webp'; // Adjust the path as necessary
import arrowRight from '../assets/right-arrow.webp'; // Adjust the path as necessary

const Reviews = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <section className="reviews">
      <h2 className="section-header">ביקורות</h2>
      <div className="reviews-slider">
        <Swiper
          loop={true}
          slidesPerView={5}
          spaceBetween={10}
          grabCursor={true}
          autoplay={{ delay: 10000 }}
          navigation={{
            nextEl: '.custom-swiper-button-prev',
            prevEl: '.custom-swiper-button-next',
          }}
          modules={[Navigation, Autoplay]}
          onSwiper={(swiper) => {
            setTimeout(() => {
              if (swiper.params) {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }
            });
          }}
          breakpoints={{
            1454: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 2,
            },
            0: {
              slidesPerView: 1,
            }
          }}
        >
          <SwiperSlide>
            <img src={img1} alt="Review 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img2} alt="Review 2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img3} alt="Review 3" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img4} alt="Review 4" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img5} alt="Review 5" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={img6} alt="Review 6" />
          </SwiperSlide>
        </Swiper>

        {/* Navigation buttons using images */}
        <button ref={nextRef} className="custom-swiper-button custom-swiper-button-prev">
          <img src={arrowLeft} alt="Previous" />
        </button>
        <button ref={prevRef} className="custom-swiper-button custom-swiper-button-next">
          <img src={arrowRight} alt="Next" />
        </button>
      </div>
    </section>
  );
};

export default Reviews;