import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HotItems.css';
import { proteins, carbs, veggies } from '../data/products';

const categories = [
  { name: 'חלבונים', items: proteins, hash: 'proteins' },
  { name: 'פחמימות', items: carbs, hash: 'carbs' },
  { name: 'ירקות', items: veggies, hash: 'veggies' }
];

const HotItems = () => {
  const [randomItems, setRandomItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const selectRandomItems = () => {
      const newItems = categories.map(category => ({
        ...category.items[Math.floor(Math.random() * category.items.length)],
        category: category.name,
        hash: category.hash
      }));
      setRandomItems(newItems);
    };

    selectRandomItems();
  }, []);

  const handleItemClick = (itemId, categoryHash) => {
    navigate(`/store#${categoryHash}`);
  };

  return (
    <section className="hot-items">
      <h2 className="section-header">הפריטים החמים שלנו</h2>
      <div className="items-container">
        {randomItems.map((item) => (
          <div key={item.id} className="item">
            <h3 className="item-category">{item.category}</h3>
            <button className="item-button" onClick={() => handleItemClick(item.id, item.hash)}>
              <div className="image-container">
                <div className="cta-overlay">לחצו עלי לעוד!</div> {/* Move this above the image */}
                <img src={item.imageUrl} alt={item.name} className="item-image" />
              </div>
              <p className="item-name">{item.name}</p>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HotItems;