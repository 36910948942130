// src/components/Hero.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Hero.css';
import logoImage from '../assets/logo.webp'; // Import the image

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-image-container">
        <img src={logoImage} alt="Logo" className="hero-image" />
      </div>
      <div className="hero-content">
        <h1>ארוחות טעימות ובריאות במשלוח עד הבית</h1>
        <p>הכן את עצמך לשבוע של אוכל טעים, מזין ומותאם אישית</p>
        <Link to="/store" className="cta-button">הזמינו עכשיו!</Link>
      </div>
    </section>
  );
};

export default Hero;