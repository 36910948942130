// src/components/BriefHowItWorks.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import personalChoice from '../assets/personal_choice.webp';
import deliveryDoor from '../assets/delivery_door.webp';
import reheatFood from '../assets/reheat_food.webp';
import '../styles/BriefHowItWorks.css';

const BriefHowItWorks = () => {
  return (
    <section className="brief-how-it-works">
      <h2 className="brief-how-it-works-header">איך זה עובד?</h2>
      <div className="brief-diagram">
        <div className="brief-diagram-item">
          <img src={personalChoice} alt="בחירה אישית" />
          <p>בחירה אישית מהתפריט</p>
        </div>
        <FaArrowLeft className="brief-arrow-icon" />
        <div className="brief-diagram-item">
          <img src={deliveryDoor} alt="מגיעים עד אליכם" />
          <p>משלוח עד הדלת</p>
        </div>
        <FaArrowLeft className="brief-arrow-icon" />
        <div className="brief-diagram-item">
          <img src={reheatFood} alt="חימום במיקרו" />
          <p>חימום וארוחה מוכנה</p>
        </div>
      </div>
    </section>
  );
};

export default BriefHowItWorks;