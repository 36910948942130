import React, { useState, useCallback, useMemo } from 'react';
import { useCart } from '../contexts/CartContext';
import '../styles/CartPage.css';
import { useNavigation } from '../utils/navigation';

const CartItem = React.memo(({ item, onEdit, onRemove }) => {
  return (
    <div className="cart-item">
      <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
      <div className="cart-item-details">
        <h3>{item.name}</h3>
        <p>משקל: {item.weight}g</p>
        <p>כמות: {item.quantity}</p>
        <p>מחיר: ₪{(item.weight / 100 * item.pricePer100g * item.quantity).toFixed(2)}</p>
        <button onClick={() => onEdit(item)}>עריכה</button>
        <button onClick={() => onRemove(item.id, item.weight)}>הסרה</button>
      </div>
    </div>
  );
});

const EditItemForm = React.memo(({ item, onUpdate, onCancel }) => {
  const [weight, setWeight] = useState(item.weight);
  const [quantity, setQuantity] = useState(item.quantity);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(item.id, item.weight, weight, quantity);
  };

  return (
    <div className="cart-item">
      <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
      <form onSubmit={handleSubmit} className="edit-item-form">
        <h3>{item.name}</h3>
        <label>
          משקל:
          <select value={weight} onChange={(e) => setWeight(Number(e.target.value))}>
            {[100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 700, 800, 900, 1000].map(weightOption => (
              <option key={weightOption} value={weightOption}>{weightOption}g</option>
            ))}
          </select>
        </label>
        <label>
          כמות:
          <select value={quantity} onChange={(e) => setQuantity(Number(e.target.value))}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(quantityOption => (
              <option key={quantityOption} value={quantityOption}>{quantityOption}</option>
            ))}
          </select>
        </label>
        <p>מחיר: ₪{(weight / 100 * item.pricePer100g * quantity).toFixed(2)}</p>
        <button type="submit">עידכון</button>
        <button type="button" onClick={onCancel}>ביטול</button>
      </form>
    </div>
  );
});

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart, updateItem } = useCart();
  const [editingItemKey, setEditingItemKey] = useState(null);
  const handleNavigation = useNavigation();

  const handleEdit = useCallback((item) => {
    setEditingItemKey(`${item.id}-${item.weight}`);
  }, []);

  const handleUpdate = useCallback((id, oldWeight, newWeight, newQuantity) => {
    updateItem(id, oldWeight, newWeight, newQuantity);
    setEditingItemKey(null);
  }, [updateItem]);

  const handleRemove = useCallback((id, weight) => {
    removeFromCart(id, weight);
  }, [removeFromCart]);

  const totalPrice = useMemo(() => 
    cartItems.reduce((total, item) => 
      total + (item.weight / 100 * item.pricePer100g * item.quantity), 
      0
    ).toFixed(2),
    [cartItems]
  );

  if (cartItems.length === 0) {
    return (
      <div className="cart-container empty-cart">
        <h1>העגלה שלך</h1>
        <p className="empty-cart-message">
          אופס! העגלה שלך ריקה. למה לא לעיין במבחר המדהים שלנו ולמצוא משהו מיוחד להוסיף? 🛍️
        </p>
      </div>
    );
  }

  return (
    <div className="cart-container">
      <h1>העגלה שלך</h1>
      <div className="cart-items">
        {cartItems.map(item => {
          const itemKey = `${item.id}-${item.weight}`;
          return editingItemKey === itemKey ? (
            <EditItemForm 
              key={itemKey}
              item={item} 
              onUpdate={handleUpdate}
              onCancel={() => setEditingItemKey(null)}
            />
          ) : (
            <CartItem 
              key={itemKey}
              item={item} 
              onEdit={handleEdit}
              onRemove={handleRemove}
            />
          );
        })}
      </div>
      <div className="cart-summary">
        {/* Display total price without shipping cost */}
        <p>סך הכל לתשלום: ₪{totalPrice}</p>

        {/* Display message for free shipping */}
        {totalPrice < 250 && (
          <p className="free-shipping-message">הוסף עוד ₪{(250 - totalPrice).toFixed(2)} להזמנה כדי לקבל משלוח חינם!</p>
        )}

        {/* Proceed to payment button */}
        <button 
          className="proceed-to-payment" 
          onClick={(e) => {e.preventDefault(); handleNavigation('/checkout');}}
        >
          מעבר לתשלום
        </button>
      </div>

      {/* Clear cart button */}
      <button className="clear-cart" onClick={clearCart}>ריקון עגלה</button>
    </div>
  );
};

export default CartPage;