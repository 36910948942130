import React, { createContext, useContext, useReducer, useCallback, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

// Action types
const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const UPDATE_ITEM = 'UPDATE_ITEM';
const CLEAR_CART = 'CLEAR_CART';
const SET_CART = 'SET_CART';

// Reducer function
const cartReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingItemIndex = state.findIndex(
        cartItem => cartItem.id === action.payload.id && cartItem.weight === action.payload.weight
      );
      if (existingItemIndex >= 0) {
        return state.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );
      }
      return [...state, action.payload];

    case REMOVE_FROM_CART:
      return state.filter(item => !(item.id === action.payload.id && item.weight === action.payload.weight));

    case UPDATE_ITEM:
      const { id, oldWeight, newWeight, newQuantity } = action.payload;
      let itemToUpdate = null;
      let existingItem = null;

      // First pass: find the items we need
      const firstPass = state.map(item => {
        if (item.id === id && item.weight === oldWeight) {
          itemToUpdate = item;
        }
        if (item.id === id && item.weight === newWeight && item !== itemToUpdate) {
          existingItem = item;
        }
        return item;
      });

      // Second pass: update or merge items
      return firstPass.map(item => {
        if (item === itemToUpdate) {
          if (existingItem) {
            // If there's an existing item, we'll merge with it later
            return null; // This will be filtered out
          } else {
            // Update the item
            return {
              ...item,
              weight: newWeight,
              quantity: newQuantity,
              totalPrice: (newWeight / 100) * item.pricePer100g * newQuantity
            };
          }
        }
        if (item === existingItem && itemToUpdate) {
          // Merge with existing item
          return {
            ...item,
            quantity: item.quantity + newQuantity,
            totalPrice: item.totalPrice + (newWeight / 100) * itemToUpdate.pricePer100g * newQuantity
          };
        }
        return item;
      }).filter(Boolean) // Remove null items
        .filter((item, index, self) => 
          index === self.findIndex(t => t.id === item.id && t.weight === item.weight)
        );

    case CLEAR_CART:
      console.log('Resetting the cart');
      return [];

    case SET_CART:
      console.log('Setting cart state:', action.payload);
      return action.payload;

    default:
      return state;
  }
};

// Create a Cart Context
const CartContext = createContext();

// Custom hook to use the Cart Context
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const CartProvider = ({ children }) => {
  const [cartItems, dispatch] = useReducer(cartReducer, []);
  const initialized = useRef(false);

  // Load cart items from cookies when the component mounts
  useEffect(() => {
    if (!initialized.current) {
      const savedCart = Cookies.get('cart');
      console.log('Loading cart from cookies:', savedCart);
      if (savedCart) {
        try {
          const parsedCart = JSON.parse(savedCart);
          console.log('Parsed cart:', parsedCart);
          dispatch({ type: SET_CART, payload: parsedCart });
        } catch (error) {
          console.error('Error parsing cart from cookies:', error);
        }
      }
      initialized.current = true;
    }
  }, []);

  // Save cart items to cookies whenever it changes
  useEffect(() => {
    if (initialized.current) {
      console.log('Saving cart to cookies:', cartItems);
      Cookies.set('cart', JSON.stringify(cartItems), { expires: 7 });
    }
  }, [cartItems]);

  const addToCart = useCallback((item) => {
    dispatch({ type: ADD_TO_CART, payload: item });
  }, []);

  const removeFromCart = useCallback((id, weight) => {
    dispatch({ type: REMOVE_FROM_CART, payload: { id, weight } });
  }, []);

  const updateItem = useCallback((id, oldWeight, newWeight, newQuantity) => {
    dispatch({ 
      type: UPDATE_ITEM, 
      payload: { id, oldWeight, newWeight, newQuantity } 
    });
  }, []);

  const clearCart = useCallback(() => {
    dispatch({ type: CLEAR_CART });
    Cookies.remove('cart');
  }, []);

  const getTotalItems = useCallback(() => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  }, [cartItems]);

  const getTotalPrice = useCallback(() => {
    return cartItems.reduce((total, item) => {
      const itemTotal = (item.weight / 100) * item.pricePer100g * item.quantity;
      return total + itemTotal;
    }, 0);
  }, [cartItems]);

  const calculateShippingCost = (totalPrice) => {
    return totalPrice < 250 ? 20 : 0;
  };
  
  const getTotalPriceWithShipping = () => {
    const subtotal = getTotalPrice();
    const shippingCost = calculateShippingCost(subtotal);
    return subtotal + shippingCost;
  };
  
  const value = {
    cartItems,
    addToCart,
    removeFromCart,
    updateItem,
    clearCart,
    getTotalItems,
    getTotalPrice,
    calculateShippingCost,
    getTotalPriceWithShipping,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};