// src/utils/formValidation.js

export const fieldLabels = {
  firstName: 'שם פרטי',
  lastName: 'שם משפחה',
  email: 'אימייל',
  phone: 'טלפון',
  zipCode: 'מיקוד',
  city: 'עיר',
  street: 'רחוב',
  houseNumber: 'מספר בית',
  apartmentNumber: 'מספר דירה',
  gateLetter: 'אות כניסה(לא חובה)'
};

export const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'zipCode', 'city', 'street', 'houseNumber', 'apartmentNumber'];

export const validateField = (name, value) => {
  const trimmedValue = value.trim();
  
  if (requiredFields.includes(name) && trimmedValue === '') {
    return `חובה למלא שדה ${fieldLabels[name]}`;
  }

  switch(name) {
    case 'email':
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedValue) ? '' : 'כתובת אימייל לא תקינה';
    case 'phone':
      return /^[0-9]{10}$/.test(trimmedValue) ? '' : 'מספר טלפון לא תקין';
    case 'zipCode':
      return /^[0-9]{7}$/.test(trimmedValue) ? '' : 'מיקוד לא תקין';
    case 'houseNumber':
      return /^\d+$/.test(trimmedValue) ? '' : 'מספר בית חייב להיות מספר';
    case 'city':
    case 'street':
    case 'firstName':
    case 'lastName':
      return /^(?=.*[a-zA-Zא-ת].*[a-zA-Zא-ת])[a-zA-Zא-ת\s]+$/.test(trimmedValue) ? '' : `${fieldLabels[name]} לא תקין`;
    case 'apartmentNumber':
      return /^\d+$/.test(trimmedValue) ? '' : 'מספר דירה חייב להיות מספר';
    case 'gateLetter':
      return trimmedValue === '' || /^[a-zA-Zא-ת]$/.test(trimmedValue) ? '' : 'אות כניסה לא תקינה';
    default:
      return '';
  }
};

export const isFormValid = (formData, errors) => {
  return requiredFields.every(field => formData[field].trim() !== '' && !errors[field]);
};