import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useNavigation } from '../utils/navigation';
import instagramIcon from '../assets/instagram-icon.webp';
import facebookIcon from '../assets/facebook-icon.webp';
import cartIcon from '../assets/cart-icon.webp';
import logo from '../assets/logo.webp';
import styles from '../styles/Header.module.css';
import { INSTAGRAM_URL, FACEBOOK_URL, WHATSAPP_URL } from '../constants/urls';
import PhoneLink from '../components/PhoneLink';

const Header = React.memo(() => {
  const { cartItems } = useCart();
  const handleNavigation = useNavigation();

  return (
    <>
    <div className={styles.headerContainer}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          <img src={logo} alt="Company Logo" className={styles.logo} />
        </div>
        <div className={styles.headerMiddle}>
          <PhoneLink />
        </div>
        <div className={styles.headerRight}>
          <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className={styles.socialIcon} />
          </a>
          <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" className={styles.socialIcon} />
          </a>
        </div>
      </header>
      
      <nav className={styles.navSection}>
        <div className={styles.navButtons}>
          <button className={styles.navButton} onClick={(e) => { e.preventDefault(); handleNavigation('/'); }}>דף הבית</button>
          <button className={styles.navButton} onClick={(e) => { e.preventDefault(); handleNavigation('/about'); }}>אודות</button>
          <button className={styles.navButton} onClick={(e) => { e.preventDefault(); handleNavigation('/about', 'faq-container'); }}>שאלות ותשובות</button>
          <button className={styles.orderButton} onClick={(e) => { e.preventDefault(); handleNavigation('/store'); }}>לתפריט</button>
        </div>
        <div className={styles.cartButtonContainer}>
          <Link to="/cart" className={styles.cartButton} title={`Items in cart: ${cartItems.length}`}>
            <span className={styles.cartText}>לקופה</span>
            <span className={styles.cartCount}>{cartItems.length}</span>
            <img src={cartIcon} alt="Cart" className={styles.cartIcon} />
          </Link>
        </div>
      </nav>
    </div>
    </>
  );
});

export default Header;