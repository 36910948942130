import React, { useState } from 'react';
import ReactGA from "react-ga4";
import { useCart } from '../contexts/CartContext';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { validateField, isFormValid, fieldLabels, requiredFields } from '../utils/formValidation';
import { PayPalButtonWrapper, createPayPalOrder } from './PayPalIntegration';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase.js';
import { useNavigation } from '../utils/navigation';
import { getInitialFormData, updateFormField, clearFormData } from '../utils/formStorage';
import '../styles/CheckoutPage.css';


// Custom validation message component
const ValidationMessage = ({ message }) => (
  <div className="validation-message">
    {message} <span className="exclamation-mark">!</span>
  </div>
);

const CheckoutPage = () => {
  const { cartItems, getTotalPrice, clearCart } = useCart();
  const [formData, setFormData] = useState(getInitialFormData);
  const [errors, setErrors] = useState({});
  const [setIsValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [userDocId, setUserDocId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fieldsLocked, setFieldsLocked] = useState(false);

  const navigate = useNavigation();
  
  const calculateShippingCost = (totalPrice) => {
    return totalPrice < 250 ? 20 : 0;
  };

  const getTotalPriceWithShipping = () => {
    const subtotal = getTotalPrice();
    const shippingCost = calculateShippingCost(subtotal);
    return subtotal + shippingCost;
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = updateFormField(formData, name, value);
    setFormData(updatedFormData);
    const error = validateField(name, value);
    setErrors(prevState => ({ ...prevState, [name]: error }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) {
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const userInfo = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          timestamp: new Date()
        };
        const docRef = await addDoc(collection(db, 'users'), userInfo);
        setUserDocId(docRef.id);
        setIsFormSubmitted(true);
        setFieldsLocked(true);
      } catch (error) {
        console.error('Error adding user info: ', error);
      }
    } else {
      console.log('Form is not valid');
    }
  };

  const handleEdit = () => {
    setFieldsLocked(false);
    setIsFormSubmitted(false);
  };


  const onPayPalApprove = async (data, actions) => {
    return actions.order.capture().then(async function (details) {
      try {
        const subtotal = getTotalPrice();
        const shippingCost = calculateShippingCost(subtotal);
        const orderData = {
          userId: userDocId,
          ...formData,
          items: [
            ...cartItems.map(item => ({
              id: item.id,
              name: item.name,
              weight: item.weight,
              quantity: item.quantity,
              price: (item.weight / 100 * item.pricePer100g * item.quantity).toFixed(2)
            })),
            {
              id: 'shipping',
              name: 'דמי משלוח',
              weight: 'N/A',
              quantity: 1,
              price: shippingCost.toFixed(2)
            }
          ],
          totalPrice: getTotalPriceWithShipping().toFixed(2),
          paymentDetails: details,
          timestamp: new Date()
        };

        const docRef = await addDoc(collection(db, 'orders'), orderData);
        console.log('Order submitted with ID: ', docRef.id);

        // GA4 Purchase Event Tracking
        ReactGA.event({
          category: "Purchase",
          action: "Completed",
          label: docRef.id, // Order ID
          value: parseFloat(orderData.totalPrice), // Total price as a number
          items: orderData.items.map(item => ({
            item_id: item.id,
            item_name: item.name,
            price: parseFloat(item.price),
            quantity: item.quantity
          }))
        });

        clearCart();
        clearFormData();
        navigate('/payment-success');
      } catch (error) {
        console.error('Error submitting order: ', error);
      }
    });
  };

  return (
    <PayPalScriptProvider options={{ 
      "client-id": "ARWAl7Z-H_lovbcxvR7TTVy8GSliCAJakv5bw3xypyNLLl7itw3iHs2BxHsfskq2F1wMB_SwW-H9DXEy", 
      currency: "ILS",
      locale: "he_IL",
      components: "buttons",
      debug: true
    }}>
      <div className="checkout-page">
        <div className="checkout-form">
          <h2>פרטי משלוח</h2>
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-fields">
              {Object.entries(formData).map(([key, value]) => (
                <div className={`form-group ${errors[key] ? 'error' : ''}`} key={key}>
                  <label htmlFor={key}>{fieldLabels[key]}:</label>
                  <input
                    type={key === 'email' ? 'email' : 'text'}
                    id={key}
                    name={key}
                    value={value}
                    onChange={handleInputChange}
                    required={requiredFields.includes(key)}
                    disabled={fieldsLocked}
                  />
                  {formSubmitted && errors[key] && <ValidationMessage message={errors[key]} />}
                </div>
              ))}
            </div>
            <div className="form-buttons">
              <button type="submit" className="continue-button" disabled={isFormSubmitted}>
                {isFormSubmitted ? 'פרטים נשמרו' : 'המשך'}
              </button>
              {fieldsLocked && (
                <button type="button" className="edit-button" onClick={handleEdit}>
                  ערוך פרטים
                </button>
              )}
              <button type="button" className="clear-button" onClick={() => {
                clearFormData();
                setFormData(getInitialFormData());
                setIsFormSubmitted(false);
                setFormSubmitted(false);
                setErrors({});
                setFieldsLocked(false);
              }}>
                נקה טופס
              </button>
            </div>
          </form>
        </div>
        <div className="order-summary">
          <h2>סיכום הזמנה</h2>
          <div className="order-items-container">
            {cartItems.map((item) => (
              <div key={`${item.id}-${item.weight}`} className="order-item">
                <span className="item-name">{item.name} ({item.weight}g)</span>
                <div className="item-details">
                  <span className="item-quantity">כמות: {item.quantity}</span>
                  <span className="item-price">₪{(item.weight / 100 * item.pricePer100g * item.quantity).toFixed(2)}</span>
                </div>
              </div>
            ))}
            {calculateShippingCost(getTotalPrice()) > 0 && (
              <div className="order-item">
                <span className="item-name">דמי משלוח</span>
                <div className="item-details">
                  <span className="item-quantity">כמות: 1</span>
                  <span className="item-price">₪20.00</span>
                </div>
              </div>
            )}
          </div>
          <div className="order-total">
            <strong>סה"כ לתשלום: ₪{getTotalPriceWithShipping().toFixed(2)}</strong>
          </div>
            <div className="paypal-button-container">
            {fieldsLocked ? (
              <PayPalButtonWrapper
                createOrder={(data, actions) => {
                  console.log('Creating order with form data:', formData);
                  return createPayPalOrder(formData, getTotalPriceWithShipping)(data, actions);
                }}
                onApprove={(data, actions) => {
                  console.log('Payment approved:', data);
                  return actions.order.capture().then(function(details) {
                    console.log('Capture result:', details);
                    onPayPalApprove(data, actions);
                  });
                }}
                onError={(err) => {
                  console.error('PayPal error:', err);
                }}
                isFormSubmitted={isFormSubmitted}
              />
            ) : (
              <p className="form-message">אנא מלא את כל פרטי הטופס לפני התשלום</p>
            )}
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default CheckoutPage;